import React from "react";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/main.page";
import NotFound from "./pages/not.found";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<MainPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import RadiusLogo from "../img/logoradius.png";

const NotFound = () => {
  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginBottom: "15px" }}
      >
        <img src={RadiusLogo} alt="radius.uz" />
      </div>
      <div className="card p-4 text-center">
        <h2>404 - Страница не найдена</h2>
        <p>Контракт не найден или URL неверный.</p>
      </div>
    </div>
  );
};

export default NotFound;

import axios from "axios";

const axiosCredentials = {
  baseURL: "https://django.radius.uz/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM0MjQ2OTA2LCJpYXQiOjE3MzE2NTQ5MDYsImp0aSI6ImJmZTEzZTY0YzYxNTQ5MmI4MzBkYWVkOWFkZDliM2IwIiwidXNlcl9pZCI6M30.Y3lk4HLOk2APmN3xaZdA61Rz_HWbJTDKoXcgKqQI-NA`,
  },
};

export const nextAxios = axios.create(axiosCredentials);
export const authAxios = axios.create(axiosCredentials);

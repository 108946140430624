import React from "react";
import { thousandSeperate } from "../utils/funcs";

interface TableComponentProps {
  data: ContractProps;
}

const TableProductPay: React.FC<TableComponentProps> = ({ data }) => {
  const titles = [
    "#",
    "Дата",
    "Планируемая сумма",
    "Фактическая сумма",
    "Просроченная сумма",
    "Просроченный день",
  ];

  return (
    <table className={"table-orderinfo"}>
      <thead>
        <tr>
          {titles.map((title, index) => (
            <th style={{ borderBottom: "0" }} key={index}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.payment_schedule.monthly.map((item, id) => (
          <tr key={id}>
            <td>{id + 1}</td>
            <td>{item.plan_date}</td>
            <td>{thousandSeperate(item.plan_price)} сум</td>
            <td>
              {item.fact_price
                ? `${thousandSeperate(item.fact_price)} сум`
                : null}
            </td>
            <td className="pinkCol bold">
              {item.delay_price
                ? `${thousandSeperate(item.delay_price)} сум`
                : null}
            </td>
            <td className="pinkCol bold">{item.delay_days || null}</td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td className="bold">Итого</td>
          <td className="bold">
            {thousandSeperate(data.payment_schedule.total.plan_price)} сум
          </td>
          <td className="bold">
            {data.payment_schedule.total.fact_price
              ? `${thousandSeperate(
                  data.payment_schedule.total.fact_price
                )} сум`
              : null}
          </td>
          <td className="pinkCol bold">
            {data.payment_schedule.total.delay_price
              ? `${thousandSeperate(
                  data.payment_schedule.total.delay_price
                )} сум`
              : null}
          </td>
          <td className="pinkCol bold">
            {data.payment_schedule.total.delay_days || null}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableProductPay;

export const formattedDate = (date: string | null | undefined) => {
  if (!date) return "";
  const newDate = new Date(date);
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const year = newDate.getFullYear();
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const thousandSeperate = (data: number | string | undefined): string => {
  return data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "";
};

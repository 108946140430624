import React from "react";
import { thousandSeperate } from "../utils/funcs";
import TableProductPay from "./table.product.pay";

interface TableComponentProps {
  data: ContractProps;
}

const TableClientPay: React.FC<TableComponentProps> = ({ data }) => {
  const titles = [
    "Наименования",
    "Магазин",
    "Срок рассрочки",
    "Платеж в месяц",
  ];

  return (
    <table className="table-clientpay scroll__bar">
      <thead className="table-clientpay-head">
        <tr>
          {titles.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody className="table-clientpay-body">
        <tr className="table-clientpay-row">
          <td>{data.nomer}</td>
          <td>{data.store.name}</td>
          <td>{data.tarif} </td>
          <td>
            {thousandSeperate(data.payment_schedule.monthly[0].plan_price)} сум
          </td>
        </tr>
        {/* {item.visible && ( */}
        <tr>
          <td colSpan={5} className={``}>
            <p className={`lbl`}>Список товаров</p>
            {data.products.map((product, idx) => (
              <div key={idx} className={`product`}>
                {idx + 1}. {product.name} - {thousandSeperate(product.price)}{" "}
                сум
              </div>
            ))}
            <TableProductPay data={data} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableClientPay;

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { authAxios } from "../utils/axios";
import { formattedDate, thousandSeperate } from "../utils/funcs";
import TableClientPay from "../components/table.client.pay";
import RadiusLogo from "../img/logoradius.png";

const MainPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ContractProps | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          navigate("/");
          return;
        }

        const response = await authAxios.get<{ result: ContractProps }>(
          `/orders/${id}/contract-data/`
        );

        if (!response.data?.result) {
          navigate("/");
          return;
        }

        setData(response.data.result);
      } catch (error) {
        console.error(error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleDownloadContract = async () => {
    try {
      setDownloading(true);
      const response = await authAxios.get(`/orders/${id}/contract/`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `contract-radius.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading contract:", error);
      alert("Ошибка при скачивании договора");
    } finally {
      setDownloading(false);
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginBottom: "15px" }}
        >
          <img src={RadiusLogo} alt="radius.uz" />
        </div>
        <div className="card p-4 text-center">
          <h3>Загрузка...</h3>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="container">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginBottom: "15px" }}
        >
          <img src={RadiusLogo} alt="radius.uz" />
        </div>
        <div className="card p-4 text-center">
          <h3>Контракт не найден</h3>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginBottom: "15px" }}
      >
        <img src={RadiusLogo} alt="radius.uz" />
      </div>
      <div className="card p-2">
        <div className="order__products order__block w-100">
          <div className="order__title">
            <div>Информация о заказе</div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Тип заказа:</div>
            <div className="order__group_value">
              {data.order_data.type_decs} {data.order_data.type_order_name}
            </div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Тип доставки:</div>
            <div className="order__group_value">
              {data.order_data.shipping_type_decs}
            </div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Магазин:</div>
            <div className="order__group_value">{data.store.name}</div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Продовец:</div>
            <div className="order__group_value">
              {data.order_data.created_by_name}
            </div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Дата заказа:</div>
            <div className="order__group_value">
              {formattedDate(data.order_data.created_at)}
            </div>
          </div>
        </div>
        <div className="order__products order__block w-100">
          <div className="order__title">
            <div>Информация о клиенте</div>
          </div>
          <div className="order__group">
            <div className="order__group_type">ФИО:</div>
            <div className="order__group_value">
              {data.order_data.client_full_name}
            </div>
          </div>
          <div className="order__group">
            <div className="order__group_type">Паспорт:</div>
            <div className="order__group_value">{`${data.order_data.client_passport_seria} ${data.order_data.client_passport_number}`}</div>
          </div>
          <div className="order__group">
            <div className="order__group_type">ПИНФЛ:</div>
            <div className="order__group_value">
              {data.order_data.client_pinfl}
            </div>
          </div>
        </div>

        <div className="order__products order__block">
          <div className="order__title">
            <div>Товары</div>
          </div>
          <div className="order__products_table scroll__bar">
            <div className="order__products_table__header">
              <div>Названия товаре</div>
              <div>Цена</div>
            </div>
            {data?.products.slice(0, 3).map((item, index) => (
              <div className="order__products_table__box" key={index}>
                <div>{item.name}</div>
                <div>{thousandSeperate(item.price)} сум</div>
              </div>
            ))}
          </div>
        </div>
        <div className="cardd">
          <p className="cardd__head">Общая информация по рассрочке</p>
          <div className="cardd__box">
            <div className="cardd__box-item">
              <div className="cardd__box-item--title">Требуемоя сумма</div>
              <div className="cardd__box-item--value">
                {thousandSeperate(data.condition.plan)} сум
              </div>
            </div>
            <div className="cardd__box-item">
              <div className="cardd__box-item--title">Оплачено</div>
              <div className="cardd__box-item--value">
                {thousandSeperate(data.condition.paid)} сум
              </div>
            </div>
            <div className="cardd__box-item">
              <div className="cardd__box-item--title">Остаток</div>
              <div className="cardd__box-item--value">
                {thousandSeperate(data.condition.debt)} сум
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <TableClientPay data={data} />
        </div>

        <div className="order__block">
          <div className="order__title">
            <div>Подписи</div>
          </div>
          <div className="signatures-container">
            {data.order_data.staff_signature ? (
              <div className="signature-section">
                <div className="order__signature">
                  <div className="order__group">
                    <div className="order__group_type">Ид:</div>
                    <div className="order__group_value">
                      {data.order_data.staff_signature.id}
                    </div>
                  </div>
                  <div className="order__group">
                    <div className="order__group_type">Имя продовца:</div>
                    <div className="order__group_value">
                      {data.order_data.staff_signature.staff_name}
                    </div>
                  </div>
                  <div className="order__group">
                    <div className="order__group_type">Дата подписи:</div>
                    <div className="order__group_value">
                      {formattedDate(
                        data.order_data.staff_signature.created_at
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="signature-section">
                <h6>Подписать продовца</h6>
              </div>
            )}
            {data.order_data.client_signature ? (
              <div className="signature-section">
                <div className="order__signature">
                  <div className="order__group">
                    <div className="order__group_type">Ид:</div>
                    <div className="order__group_value">
                      {data.order_data.client_signature.id}
                    </div>
                  </div>
                  <div className="order__group">
                    <div className="order__group_type">Имя клиента:</div>
                    <div className="order__group_value">
                      {data.order_data.client_signature.client_name}
                    </div>
                  </div>
                  <div className="order__group">
                    <div className="order__group_type">Дата подписи:</div>
                    <div className="order__group_value">
                      {formattedDate(
                        data.order_data.client_signature.created_at
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="signature-section">
                <h6>Подписать клиента</h6>
              </div>
            )}
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginBottom: "15px" }}
        >
          <button
            className="btn btn-primary"
            onClick={handleDownloadContract}
            disabled={downloading}
          >
            {downloading ? "Скачивание..." : "Скачать договор"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
